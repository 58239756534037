// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAGkIjVdQjFoGvvPFS7PGRc2iU8Ee3FeAY",
  authDomain: "bokkah.com",
  projectId: "react-recipes-app-bd56e",
  storageBucket: "react-recipes-app-bd56e.appspot.com",
  messagingSenderId: "934303504080",
  appId: "1:934303504080:web:62776ee70d979c232370d5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

// Initialize Storage
export const storage = getStorage(app);
