import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import CategorySelect from '../UI/CategorySelect';

interface RecipeFormProps {
  inputData?: any;
  onSubmit: (data: any) => void;
  children: React.ReactNode;
  onImageChange: (file: any) => void;
}

export default function RecipeForm({
  inputData,
  onSubmit,
  children,
  onImageChange,
}: RecipeFormProps) {
  const [image, setImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [steps, setSteps] = useState<string[]>([]);
  const [ingredients, setIngredients] = useState<string[]>([]);

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const trashButtonClasses =
    'rounded-full shadow-transparent hover:shadow-lg size-10 p-2 -my-2 text-gray-700 hover:text-gray-900 transition-all outline-none focus:ring-4';

  const recipe = inputData;

  const { register, setValue, getValues } = useForm({
    defaultValues: {
      title: recipe?.title || '',
      image: recipe?.image || '',
      ingredients: recipe?.ingredients || [],
      steps: recipe?.steps || [],
      category: recipe?.category || '',
      country: recipe?.country || '',
    },
  });

  const [selected, setSelected] = useState<{ name: string }>({
    name: getValues('category'),
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setImageFile(file || null);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setValue('image', imageUrl);
      setImage(imageUrl);
      onImageChange(file);
    }
  };

  const handleAddStep = () => {
    const currentSteps = getValues('steps') || [];
    setSteps([...currentSteps, '']);
    setValue('steps', [...currentSteps, '']);
  };

  const handleStepChange = (index: number, value: string) => {
    const currentSteps = getValues('steps') || [];
    currentSteps[index] = value;
    setValue('steps', currentSteps);
  };

  const handleRemoveStep = (index: number) => {
    const currentSteps = getValues('steps');
    const updatedSteps = currentSteps.filter(
      (_: any, i: number) => i !== index
    );

    // Set the updated values directly using setValue
    setValue('steps', updatedSteps);
    setSteps(updatedSteps);
  };

  const handleAddIngredient = () => {
    const currentIngredients = getValues('ingredients') || [];
    setIngredients([...currentIngredients, '']);
    setValue('ingredients', [...currentIngredients, '']);
  };

  const handleIngredientChange = (index: number, value: string) => {
    const currentIngredients = getValues('ingredients') || [];
    currentIngredients[index] = value;
    setValue('ingredients', currentIngredients);
  };

  const handleRemoveIngredient = (index: number) => {
    const currentIngredients = getValues('ingredients');
    const updatedIngredients = currentIngredients.filter(
      (_: any, i: number) => i !== index
    );

    // Set the updated values directly using setValue
    setValue('ingredients', updatedIngredients);
    setIngredients(updatedIngredients);
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setValue('category', selected.name);

    const initialImage = recipe?.image;
    const formData = new FormData(event.target as HTMLFormElement);

    if (imageFile) {
      formData.append('image', imageFile);
      onImageChange(imageFile);
    } else {
      formData.append('image', initialImage);
      onImageChange(null);
    }

    if (getValues('ingredients').length === 0) {
      alert('Ingredients missing');
      handleAddIngredient();
      return;
    }

    if (getValues('steps').length === 0) {
      alert('Steps missing');
      handleAddStep();
      return;
    }

    const data = Object.fromEntries(formData);

    onSubmit({ ...data });
  }

  return (
    <form className='p-8 bg-white shadow-xl rounded-xl' onSubmit={handleSubmit}>
      <div className='mb-8'>
        <label htmlFor='title' className={labelClasses}>
          Recipe title
        </label>
        <input
          id='title'
          {...register('title')}
          placeholder='Recipe title'
          className={inputClasses}
          required
          autoFocus
          autoComplete='off'
        />
      </div>

      <div className='flex flex-col md:flex-row md:gap-8 mb-8'>
        <div>
          <label htmlFor='image' className={labelClasses}>
            {recipe?.image || image ? 'Your current image' : 'Image Upload'}
          </label>
          <div className='w-full md:w-[12rem]'>
            <label htmlFor='image'>
              {recipe?.image || image ? (
                <img
                  src={
                    getValues('image') ||
                    recipe?.image ||
                    image ||
                    '/logo512.png'
                  }
                  alt={recipe?.title}
                  className='rounded-md object-cover w-full h-[8rem] bg-gray-200'
                />
              ) : (
                <div className='rounded-md object-cover w-full h-[8rem] bg-gray-100 flex items-center justify-center'>
                  <PhotoIcon className='size-12 text-gray-300' />
                </div>
              )}
            </label>
          </div>
        </div>
        <div className='w-full hidden md:block md:mt-12'>
          <p className='mb-4 text-sm'>Would you like to replace the image?</p>
          <input
            id='image'
            name='image'
            type='file'
            accept='image/*'
            onChange={handleImageChange}
            required={recipe?.image === undefined}
            className='appearance-none rounded focus:p-2 focus:-m-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all'
          />
        </div>
      </div>

      <div className='mb-8'>
        <label htmlFor='category' className={labelClasses}>
          Recipe category
        </label>
        {/* @ts-ignore */}
        <input {...register('category')} value={selected} required hidden />
        <CategorySelect
          id='category'
          name='category'
          selected={selected}
          setSelected={setSelected}
        />
      </div>

      <div className='mb-8'>
        <label className={labelClasses}>Ingredients</label>
        {getValues('ingredients')?.map((ingredient: string, index: number) => (
          <div
            key={index}
            className='mb-4 flex justify-between items-center gap-4'
          >
            <input
              {...register(`ingredients.${index}`)}
              placeholder={`Ingredient ${index + 1}`}
              className={inputClasses}
              autoFocus
              onChange={(e) => handleIngredientChange(index, e.target.value)}
              required
              autoComplete='off'
            />
            <button
              type='button'
              className={trashButtonClasses}
              onClick={() => handleRemoveIngredient(index)}
              title='Click to remove this ingredient'
            >
              <TrashIcon />
            </button>
          </div>
        ))}
        <button
          type='button'
          onClick={handleAddIngredient}
          className='border px-4 py-2 outline-none focus:ring-4 rounded'
        >
          + Add Ingredient
        </button>
      </div>

      <div className='mb-8'>
        <label className={labelClasses}>Steps</label>
        {getValues('steps')?.map((step: string, index: number) => (
          <div
            key={index}
            className='mb-4 flex justify-between items-center gap-4'
          >
            <span>{index + 1}</span>
            <textarea
              {...register(`steps.${index}`)}
              placeholder={`Step ${index + 1}`}
              className={inputClasses}
              autoFocus
              onChange={(e) => handleStepChange(index, e.target.value)}
              rows={3}
              required
            />
            <button
              type='button'
              className={trashButtonClasses}
              onClick={() => handleRemoveStep(index)}
              title='Click to remove this step'
            >
              <TrashIcon />
            </button>
          </div>
        ))}
        <button
          type='button'
          onClick={handleAddStep}
          className='border px-4 py-2 outline-none focus:ring-4 rounded'
        >
          + Add Step
        </button>
      </div>

      <div className='pt-4 min-h-[4rem] flex items-center'>{children}</div>
    </form>
  );
}
