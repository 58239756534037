import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './util/http';

import './index.css';

// Error
import Error from './pages/Error';

// Recipes
import Recipes from './components/Recipes/Recipes';
import NewRecipe from './components/Recipes/NewRecipe';
import RootLayout from './pages/Root';
import RecipeDetail from './components/Recipes/RecipeDetail';
// import RecipeDetailAlt from './components/Recipes/RecipeDetailAlt';
import EditRecipe, {
  loader as editRecipeLoader,
} from './components/Recipes/EditRecipe';
import SearchRecipes from './components/Recipes/SearchRecipes';
import Categories from './components/Categories/Categories';
import CategoryDetail from './components/Categories/CategoryDetail';

// Users
import { UserProvider } from './store/UserContext';
import NewUser from './components/Users/NewUser';
import Users from './components/Users/Users';
import UserDetail from './components/Users/UserDetail';
import EditUser, {
  loader as editUserLoader,
  action as editUserAction,
} from './components/Users/EditUser';
// import NewUser from './components/Users/NewUser';
import UserFavorties from './components/Users/UserFavorites';
import UserFriends from './components/Users/UserFriends';

// Login
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';

// Dashboard
import Notifications from './components/Dashboard/Notifications';
import Dashboard from './components/Dashboard/Dashboard';
import UserCollections from './components/Users/UserCollections';

// Collections
import Collections from './components/Collections/Collections';
import CollectionDetail from './components/Collections/CollectionDetail';
import NewCollection from './components/Collections/NewCollection';
import EditCollection, {
  loader as editCollectionLoader,
} from './components/Collections/EditCollection';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <Error error='An error occured. Pleace try again later.' />,
    children: [
      {
        index: true,
        element: <SearchRecipes />,
      },
      {
        path: 'recipes',
        children: [
          { index: true, element: <Recipes /> },
          {
            path: ':recipeId',
            id: 'recipe-detail',
            children: [
              { index: true, element: <RecipeDetail /> },
              // { index: true, element: <RecipeDetailAlt /> },
              {
                path: 'edit',
                element: <EditRecipe />,
                loader: editRecipeLoader,
              },
            ],
          },
          { path: 'new', element: <NewRecipe /> },
        ],
      },
      {
        path: 'categories',
        children: [
          { index: true, element: <Categories /> },
          {
            path: ':slug',
            id: 'category-detail',
            element: <CategoryDetail />,
          },
        ],
      },
      {
        path: 'collections',
        children: [
          { index: true, element: <Collections /> },
          {
            path: ':collectionId',
            id: 'collection-detail',
            children: [
              { index: true, element: <CollectionDetail /> },
              {
                path: 'edit',
                element: <EditCollection />,
                loader: editCollectionLoader,
              },
            ],
          },
          { path: 'new', element: <NewCollection /> },
        ],
      },
      {
        path: 'users',
        children: [
          { index: true, element: <Users /> },
          {
            path: ':username',
            id: 'user-detail',
            children: [
              { index: true, element: <UserDetail /> },
              {
                path: 'edit',
                element: <EditUser />,
                loader: editUserLoader,
                action: editUserAction,
              },
              { path: 'favorites', element: <UserFavorties /> },
              { path: 'collections', element: <UserCollections /> },
              { path: 'friends', element: <UserFriends /> },
              {
                path: 'friends/recipes',
                element: <UserFriends onlyRecipes={true} />,
              },
            ],
          },
          { path: 'new', element: <NewUser /> },
        ],
      },
      {
        path: 'dashboard',
        children: [
          { index: true, element: <Dashboard /> },
          { path: 'notifications', element: <Notifications /> },
        ],
      },
      { path: 'sign-up', element: <SignUp /> },
      { path: 'login', element: <Login /> },
      { path: 'reset-password', element: <ResetPassword /> },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
