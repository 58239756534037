import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  addToFriends,
  deactivateUser,
  fetchNotifications,
  fetchUser,
  queryClient,
} from '../../util/http';
import { useUser } from '../../store/UserContext';

import UserRecipes from './UserRecipes';
import ErrorBlock from '../UI/ErrorBlock';
import Modal from '../UI/Modal';
import Section from '../UI/Section';
import Button from '../UI/Button';
import H2 from '../UI/H2';
import LoadingIndicator from '../UI/LoadingIndicator';
import { PencilIcon } from '@heroicons/react/24/outline';
import UsersCollectionsSection from './UserCollectionsSection';
import { User } from '../../types';

export default function UserDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useUser();

  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);

  const { data: userData, isPending: isUserDataPending } = useQuery({
    queryKey: ['users', params.username],
    queryFn: ({ signal }) => fetchUser({ username: params.username, signal }),
  });

  const {
    mutate: deactivateUserMutation,
    isPending: isPendingDeactivation,
    isError: isErrorDeactivating,
    error: deactivationError,
  } = useMutation({
    mutationFn: deactivateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
        refetchType: 'none',
      });
      navigate('/users');
    },
  });

  const userId = userData?.uid;

  // Then get the user's notifications
  const { data: notifications, fetchStatus } = useQuery({
    queryKey: ['notifications', userId],
    queryFn: ({ signal }) =>
      fetchNotifications({ uid: userId, signal, max: 99 }),
    enabled: !!userId, // The query will not execute until the userId exists
    // staleTime: 300000, // 30 mins
    // gcTime: 30000, // cache will expire in 30 secs
  });

  function handleAddToFriends(uid: string) {
    setIsSendingRequest(true);

    addToFriends({
      uid: user?.uid,
      notifier: uid,
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] });
        setIsSendingRequest(false);
      })
      .catch((error) =>
        console.error('Error updating notification status:', error)
      );
  }

  function handleStartDeactivation() {
    // console.log('start deactivation');
    setIsDeactivating(true);
  }

  function handleStopDeactivation() {
    // console.log('stop deactivation');
    setIsDeactivating(false);
  }

  function handleDeactivate() {
    deactivateUserMutation({ username: params.username });
  }

  function renderAddToFriendsButton(userData: User) {
    let alreadyFriends;

    const isPendingRequest = notifications?.some((notification: any) => {
      alreadyFriends = userData.friends?.includes(user?.uid);

      if (
        notification.from === user?.uid &&
        notification.type === 'FRIEND_REQUEST' &&
        notification.status === 'PENDING' &&
        !alreadyFriends
      ) {
        return true;
      }

      return false;
    });

    return (
      <Button
        isPrimary={!alreadyFriends}
        onClick={() => handleAddToFriends(userData.uid)}
        disabled={
          fetchStatus === 'fetching' ||
          isSendingRequest ||
          isPendingRequest ||
          alreadyFriends
        }
      >
        {isPendingRequest
          ? 'Pending request'
          : alreadyFriends
          ? 'Already friends'
          : 'Add to Friends'}
      </Button>
    );
  }

  let content;

  if (isUserDataPending) {
    content = (
      <div className='mt-4'>
        <LoadingIndicator />
      </div>
    );
  }

  if (userData) {
    const isSameUser = user?.uid === userData?.uid && user !== null;

    content = (
      <Section>
        <div className='bg-white shadow-xl rounded-xl m-4 sm:m-auto max-w-2xl'>
          <div className='flex justify-center mt-28'>
            <img
              src={userData.image ?? '/logo512.png'}
              alt={`Portrait of ${userData.name}`}
              className='rounded-full size-[12rem] relative -mt-28 bg-skeleton border-4 border-white shadow-lg'
            />
          </div>
          <div className='flex px-12 sm:px-16 pb-12 gap-12 flex-col sm:flex-row text-pretty'>
            <div className='w-full text-center text-pretty'>
              <h1 className='text-center text-2xl font-bold p-8 text-gray-700'>
                {userData.name ?? userData.username}
              </h1>
              {userData.bio && <p>{userData.bio}</p>}
            </div>
          </div>
          <div className='px-12 pb-12 flex flex-col sm:flex-row gap-4 justify-center w-full text-center md:text-left'>
            {user && user?.uid !== userData.uid
              ? renderAddToFriendsButton(userData)
              : user && (
                  <Button
                    to={`/users/${userData.username}/edit/`}
                    isPrimary
                    className='flex gap-2 items-center justify-center'
                  >
                    <PencilIcon className='w-4 h-4' /> Edit Profile
                  </Button>
                )}
            <Button to={`/users/${userData.username}/friends/`}>
              {isSameUser ? 'My ' : ''}Friends
            </Button>
            <Button to={`/users/${userData.username}/friends/recipes/`}>
              {isSameUser ? 'My ' : ''}Friends' Recipes
            </Button>
          </div>
        </div>
        {userData.recipes !== null && (
          <UserRecipes user={userData} isSameUser={isSameUser} />
        )}
        <UsersCollectionsSection isSameUser={isSameUser} />
      </Section>
    );
  }

  return (
    <>
      {userData && (
        // @ts-ignore
        <Helmet>
          <title>{`${userData?.name}'s profile`}</title>
          <meta property='og:title' content={`${userData?.name}'s profile`} />
          <meta property='og:image' content={userData?.image} />
          <meta property='og:description' content={`${userData?.bio}`} />
        </Helmet>
      )}

      {isDeactivating && (
        <Modal>
          <H2 title='Are you sure?' />
          <p>Do you really want to deactivate this user?</p>
          <div className='flex'>
            {isPendingDeactivation && <p>Deactivating, please wait...</p>}
            {!isPendingDeactivation && (
              <>
                <button
                  onClick={handleStopDeactivation}
                  className='py-2 px-4 rounded-lg border border-gray-400'
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeactivate}
                  className='py-2 px-4 rounded-lg border text-red-100 bg-red-600'
                >
                  Deactivate
                </button>
              </>
            )}
          </div>
          {/* {isErrorDeactivating && (
            <ErrorBlock
              title='Failed to deactivate user'
              message={
                deactivationerror?.message ||
                'Failed to deactivate user, please try again later.'
              }
            />
          )} */}
        </Modal>
      )}
      {content}
    </>
  );
}
