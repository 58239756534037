import React, { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createNewRecipe, queryClient } from '../../util/http';
import { useUser } from '../../store/UserContext';
import RecipeForm from './RecipeForm';
import Section from '../UI/Section';
import Button from '../UI/Button';
import ErrorBlock from '../UI/ErrorBlock';
import { uploadImage } from '../../util/image';
import { Recipe } from '../../types';

export default function NewRecipe() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const collectionId = searchParams.get('collectionId');

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: createNewRecipe,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['recipes'] });
      collectionId
        ? navigate(`/collections/${collectionId}/`)
        : navigate('/recipes');
    },
  });

  useEffect(() => {
    if (user === null) {
      navigate('/');
    }
  }, [user, navigate]);

  function handleImageChange(file: File) {
    setImageFile(file);
  }

  function handleSubmit(formData: Recipe) {
    setIsSubmitting(true);

    const ingredients = [];
    const steps = [];

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key.startsWith('ingredients.')) {
          // @ts-ignore
          ingredients.push(formData[key]);
        } else if (key.startsWith('steps.')) {
          // @ts-ignore
          steps.push(formData[key]);
        }
      }
    }

    formData = {
      title: formData.title,
      category: formData.category,
      image: formData.image ?? '/logo512.png',
      ingredients: ingredients,
      steps: steps,
      user: user.uid,
    };

    // Check if an image file is selected
    if (formData.image) {
      uploadImage(formData.image)
        .then((imageUrl) => {
          formData.image = imageUrl;
          mutate({ recipe: formData, collectionId });
        })
        .catch((uploadError) => {
          console.error('Error uploading image:', uploadError);
        });
    } else {
      mutate({ recipe: formData, collectionId });
    }
  }

  return (
    <Section isNarrow title='Add Your New Recipe' hasIcons={false}>
      <RecipeForm onSubmit={handleSubmit} onImageChange={handleImageChange}>
        {isPending && 'Submitting...'}
        {!isPending && (
          <div className='flex gap-4'>
            <Button type='submit' isPrimary disabled={isSubmitting}>
              Create Recipe
            </Button>
            <Button
              onClick={() => navigate(-1)}
              className='border-transparent hover:border-gray-400'
            >
              Cancel
            </Button>
          </div>
        )}
      </RecipeForm>
      {isError && (
        <ErrorBlock
          title='Failed to create recipe'
          message={
            error?.message ||
            'Failed to create recipe. Please check your inputs and try again later.'
          }
        />
      )}
    </Section>
  );
}
