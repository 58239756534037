import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../util/firebase';

import ContentBox from '../components/UI/ContentBox';
import Section from '../components/UI/Section';
import ErrorBlock from '../components/UI/ErrorBlock';
import Button from '../components/UI/Button';

export default function SignUp() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const labelClasses = 'block text-gray-800 font-bold mb-4 cursor-pointer';
  const inputClasses =
    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-4 transition-all';

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await createUserWithEmailAndPassword(auth, email, password);

      const user = {
        email,
        id: auth?.currentUser?.uid,
        uid: auth?.currentUser?.uid,
      };

      navigate(
        `/users/new/?uid=${user.uid}&email=${encodeURIComponent(
          user.email
        )}&title=Complete your profile`
      );
      // navigate(0);
    } catch (error: any) {
      let errorMessage = '';

      if (error.code) {
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'Invalid email address.';
            break;
          case 'auth/missing-password':
            errorMessage = 'Password is missing.';
            break;
          case 'auth/weak-password':
            errorMessage = 'Password should be at least 6 characters.';
            break;
          case 'auth/email-already-in-use':
            errorMessage =
              'An account with this email address already exists. Please use a different email or try logging in.';
            break;
          default:
            errorMessage = 'An error occurred. Please try again.';
        }
      } else {
        errorMessage = 'An error occurred. Please try again.';
      }

      setError(errorMessage);
    }
  };

  return (
    <Section
      isNarrow
      title='Create New Account'
      hasIcons={false}
      wrapperClasses='max-w-lg m-auto'
    >
      <ContentBox className=''>
        <form onSubmit={handleSubmit} className='flex flex-col gap-x-4'>
          <div className='flex flex-col'>
            <div className='mb-8 w-full'>
              <label htmlFor='email' className={labelClasses}>
                Your e-mail
              </label>
              <input
                id='email'
                name='email'
                className={inputClasses}
                placeholder='E-mail address'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                required
                autoFocus
              />
            </div>
            <div className='mb-8 w-full'>
              <label htmlFor='password' className={labelClasses}>
                Your password
              </label>
              <input
                id='password'
                name='password'
                className={inputClasses}
                type='password'
                placeholder='Password'
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className='flex gap-4 items-center'>
            <Button onClick={() => handleSubmit} isPrimary={true}>
              Create account
            </Button>
          </div>
        </form>
      </ContentBox>
      <p className='m-12 flex flex-col sm:flex-row items-center gap-4 justify-center text-sm text-gray-700 text-center'>
        Have already account?{' '}
        <Button
          to='/login'
          className=''
          isPrimary={false}
          isHuge={false}
          disabled={false}
          title=''
        >
          <b>Login</b>
        </Button>
      </p>
      {error && <ErrorBlock title='Failed to create account' message={error} />}
    </Section>
  );
}
