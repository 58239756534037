import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import {
  Link,
  redirect,
  useParams,
  useSubmit,
  useNavigation,
} from 'react-router-dom';

import UserForm from './UserForm';
import ErrorBlock from '../UI/ErrorBlock';
import { fetchUser, updateUser, queryClient } from '../../util/http';
import { uploadImage } from '../../util/image';
import { useUser } from '../../store/UserContext';
import Button from '../UI/Button';

export default function EditUser() {
  const params = useParams();
  const submit = useSubmit();
  const { state } = useNavigation();

  const { user } = useUser();
  const [imageFile, setImageFile] = useState<File | null>(null);

  const { data, isError, error } = useQuery({
    queryKey: ['users', params.username],
    queryFn: ({ signal }) => fetchUser({ username: params.username, signal }),
    staleTime: 10000,
  });

  function handleImageChange(file: File) {
    setImageFile(file);
  }

  function handleSubmit(formData: HTMLFormElement) {
    // Check if an image file is selected
    if (imageFile) {
      uploadImage(imageFile, user.username)
        .then((imageUrl) => {
          formData.image = imageUrl;
          submit(formData, {
            method: 'PUT',
          });

          // Update current logged user's avatar
          const loggedUserAvatar = document.querySelector(
            '#loggedUserAvatar img'
          ) as HTMLImageElement;
          if (loggedUserAvatar) {
            loggedUserAvatar.src = imageUrl;
          }
        })
        .catch((uploadError) => {
          console.error('Error uploading image:', uploadError);
        });
    } else {
      submit(formData, {
        method: 'PUT',
      });
    }
  }

  let content;

  if (isError) {
    content = (
      <>
        <ErrorBlock
          title='Failed to load user.'
          message={
            error?.message ||
            'Failed to load user. Please check your inputs and try again later.'
          }
        />
        <div className='form-actions'>
          <Link to='../' className='button'>
            Okay
          </Link>
        </div>
      </>
    );
  }

  if (data) {
    content = (
      <section className='max-w-3xl m-auto'>
        <h1 className='text-center text-3xl font-bold p-8 text-gray-700'>
          Edit Your Profile
        </h1>
        <UserForm
          inputData={data}
          onSubmit={handleSubmit}
          onImageChange={handleImageChange}
        >
          {state === 'submitting' ? (
            <p>Sending data...</p>
          ) : (
            <div className='flex gap-4 justify-start'>
              <Button type='submit' isPrimary>
                Update
              </Button>
              <Button to='../' className='px-4 py-2'>
                Cancel
              </Button>
            </div>
          )}
        </UserForm>
      </section>
    );
  }

  return <div>{content}</div>;
}

// This loader() will generate the initial cache
// and it will be, thus, faster to show data when the component executes
// @ts-ignore
export function loader({ params }) {
  // `params` Obj provided by React

  return queryClient.fetchQuery({
    queryKey: ['users', params.username],
    queryFn: ({ signal }) => fetchUser({ username: params.username, signal }),
  });
}

// Will be trigger by React when a form is submitted
// @ts-ignore
export async function action({ request, params }) {
  const formData = await request.formData(); // Built in method provided by React
  const updateUserData = Object.fromEntries(formData);

  await updateUser({ username: params.username, user: updateUserData });
  await queryClient.invalidateQueries({ queryKey: ['users'] });
  return redirect('../');
}
