import React, { useState } from 'react';
import SelectCollectionModal from '../Collections/SelectCollectionModal';
import { BookmarkIcon } from '@heroicons/react/24/outline';

export default function AddToCollectionsButton({
  className,
  recipeId,
  recipeTitle,
  hasOpacity = false,
}: {
  className?: string;
  recipeId: string;
  recipeTitle: string;
  hasOpacity?: boolean;
}) {
  const [showCollections, setShowCollections] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowCollections(true)}
        className={`p-4 -m-4 gap-2 flex items-center justify-center ${
          hasOpacity &&
          'opacity-60 hover:opacity-100 rounded-full shadow hover:shadow-lg transition-all'
        } ${className}`}
        title='Save to collection'
      >
        {hasOpacity && <span className='font-bold hidden sm:block'>Save</span>}
        <BookmarkIcon className='w-6 h-6' />
      </button>
      {showCollections && (
        <SelectCollectionModal
          recipeId={recipeId}
          recipeTitle={recipeTitle}
          onClose={() => setShowCollections(false)}
        />
      )}
    </>
  );
}
