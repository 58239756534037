import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ErrorBlock from '../UI/ErrorBlock';
import {
  updateCollection,
  queryClient,
  fetchCollection,
} from '../../util/http.js';

import Button from '../UI/Button';
import CollectionForm from './CollectionForm';
import { useUser } from '../../store/UserContext';
import { Collection } from '../../types/collection';
import Section from '../UI/Section';

export default function EditCollection() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();
  const [collectionId, setCollectionId] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  const { data, isError, error } = useQuery({
    queryKey: ['collections', params.collectionId],
    queryFn: ({ signal }) =>
      fetchCollection({ collectionId: params.collectionId, signal }),
    staleTime: 10000,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: updateCollection,
    onSuccess: (data) => {
      setCollectionId(data.id);
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      queryClient.invalidateQueries({
        queryKey: ['user-collections', user.username],
      });
      navigate(-1); // Go back
    },
  });

  function handleSubmit(formData: Collection) {
    const collectionData = {
      uid: user.uid,
      username: user.username,
      name: formData.name,
      description: formData.description,
      private: formData.private,
    };

    mutate({
      id: params.collectionId,
      collection: collectionData,
      user: user.uid,
      username: user.username,
    });
  }

  let content: JSX.Element | null = null;

  if (isError) {
    content = (
      <ErrorBlock
        title='Failed to fetch collection'
        message={
          error?.message ||
          'Failed to fetch collection. Please try again later.'
        }
      />
    );
  }

  if (data) {
    content = (
      <Section isNarrow title='Edit Your Colection' hasIcons={false}>
        <CollectionForm inputData={data} onSubmit={handleSubmit}>
          {isPending && 'Submitting...'}
          {!isPending && (
            <div className='flex gap-4 justify-start'>
              <Button type='submit' isPrimary>
                Save
              </Button>
              <Button
                type='button'
                onClick={() => navigate(-1)}
                className='px-4 py-2'
              >
                Cancel
              </Button>
            </div>
          )}
        </CollectionForm>
      </Section>
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Edit Collection | Bokkah</title>
        <meta name='description' content='Edit your collection' />
      </Helmet>
      <div>{content}</div>
    </>
  );
}

// This loader() will generate the initial cache
// and it will be, thus, faster to show data when the component executes
// @ts-ignore
export function loader({ params }) {
  // `params` Obj provided by React

  return queryClient.fetchQuery({
    queryKey: ['recipes', params.recipeId],
    queryFn: ({ signal }) =>
      fetchCollection({ collectionId: params.collectionId, signal }),
  });
}
