import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth } from '../util/firebase';
import { fetchUserByUid } from '../util/http';

const UserContext = createContext<any>(null);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser && authUser !== null) {
        const token = await getIdToken(authUser);

        try {
          const userData = await fetchUserByUid({
            uid: authUser.uid,
            signal: null,
          });

          if (userData) {
            setUser({ ...authUser, ...userData, token });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const signIn = (authUser: any) => {
    const token = authUser ? getIdToken(authUser) : null;
    setUser(authUser ? { ...authUser, token } : null);
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
