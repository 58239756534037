import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { fetchRecipes } from '../../util/http';

import Section from '../UI/Section';
import ErrorBlock from '../UI/ErrorBlock';
import RecipeCard from '../Recipes/RecipeCard';
import RecipesSkeleton from '../UI/RecipesSkeleton';
import { Recipe } from '../../types';

export default function CategoryDetail() {
  const params = useParams();
  const category = params.slug;

  const [searchParams] = useSearchParams();

  const searchTerm = searchParams.get('search');

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['recipes', { searchTerm, category, max: 99 }],
    queryFn: ({ signal, queryKey }) =>
      fetchRecipes({ signal, searchTerm: undefined, category, max: 99 }), // `...queryKey[2]` = `max: 99`
    staleTime: 300000, // 30 mins
    // gcTime: 30000, // cache will expire in 30 secs
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = <RecipesSkeleton />;
  }

  if (isError) {
    content = (
      <ErrorBlock
        title='An error occurred'
        message={error?.message || 'Failed to fetch recipes.'}
      />
    );
  }

  if (data) {
    data.sort((a: any, b: any) =>
      a.createdAt.seconds?.localeCompare(b.createdAt.seconds)
    );

    content =
      data.length === 0 ? (
        <div className='mt-4 max-w-lg m-auto p-12 bg-white shadow flex flex-col gap-12'>
          <p className='text-center'>No recipes added yet.</p>
        </div>
      ) : (
        <div className='flex flex-wrap justify-center text-gray-800'>
          {data.map((recipe: Recipe) => {
            const user = recipe.user;

            return <RecipeCard user={user} recipe={recipe} key={recipe.id} />;
          })}
        </div>
      );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`Recipes added in "${category}" (${data?.length})`}</title>
        <meta
          property='og:title'
          content={`Recipes added in "${category}" (${data?.length})`}
        />
      </Helmet>
      <Section
        title={category ? `Recipes added in "${category}"` : 'No recipes found'}
      >
        {content}
      </Section>
    </>
  );
}
